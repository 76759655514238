<template>
  <!-- Need to add height inherit because Vue 2 don't support multiple root ele -->
  <div style="height: inherit">
    <div
      class="body-content-overlay"
      :class="{ show: shallShowUserProfileSidebar || shallShowActiveChatContactSidebar || mqShallShowLeftSidebar }"
      @click="mqShallShowLeftSidebar = shallShowActiveChatContactSidebar = shallShowUserProfileSidebar = false"
    />

    <!-- Main Area -->
    <section class="chat-app-window">
      <!-- Start Chat Logo -->
      <div
        v-if="activeConversation === null"
        class="start-chat-area"
      >
        <div class="mb-1 start-chat-icon">
          <feather-icon
            icon="MessageSquareIcon"
            size="56"
          />
        </div>
        <h4
          v-if="isConnected"
          class="sidebar-toggle start-chat-text"
          @click="$bvModal.show('create-modal')"
        >
          Inizia Conversazione
        </h4>
        <h4
          v-if="!isConnected"
          class="sidebar-toggle start-chat-text"
        >
          {{ this.statusString }}
        </h4>
      </div>

      <!-- Chat Content -->
      <div
        v-else
        class="active-chat"
      >
        <!-- Chat Navbar -->
        <div class="chat-navbar">
          <header class="chat-header">
            <div
              v-if="activeConversationDetails"
              class="d-flex align-items-center"
            >
              <b-dropdown
                size="sm"
                variant="transparent"
                class="participants-dropdown"
              >
                <template #button-content>
                  <b-avatar
                    size="50"
                    :src="activeParticipant.avatar_file ? mediaEndpoint + activeParticipant.avatar_file : ''"
                    :text="avatarText(activeParticipant.display_name)"
                    :variant="`light-${resolveUserRoleVariant(activeParticipant.role_code)}`"
                  />
                  <span class="ml-2">{{ activeParticipant.display_name }}</span></template>

                <b-dropdown-item
                  v-for="participant in activeConversationDetails.participants[0]"
                  v-if="participant.id !== activeParticipant.id"
                  :key="participant.id"
                  @click="setActiveParticipant(participant)"
                >
                  <b-avatar
                    size="50"
                    :src="participant.avatar_file ? mediaEndpoint + participant.avatar_file : ''"
                    :text="avatarText(participant.display_name)"
                    :variant="`light-${resolveUserRoleVariant(participant.role_code)}`"
                  />
                  <span class="ml-2">{{ participant.display_name }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>

            <!-- Avatar & Name -->
            <div class="d-flex align-items-center">
              <!-- Toggle Icon -->
              <div class="sidebar-toggle d-block d-lg-none mr-1">
                <feather-icon
                  icon="MenuIcon"
                  class="cursor-pointer"
                  size="21"
                  @click="mqShallShowLeftSidebar = true"
                />
              </div>

              <!-- <b-avatar
                v-if="activeConversation"
                size="36"
                :src="activeConversation"
                class="mr-1 cursor-pointer badge-minimal"
                badge
                :badge-variant="resolveAvatarBadgeVariant(activeConversation.status)"
                @click.native="shallShowActiveChatContactSidebar = true"
              />
              <h6 class="mb-0">
                {{ activeConversation }}
              </h6> -->
            </div>

            <!-- Contact Actions -->
            <div class="d-flex align-items-center">
              <b-button
                variant="primary"
                @click="$bvModal.show('create-modal')"
              >
                Nuova Conversazione
              </b-button>
            </div>
          </header>
        </div>

        <!-- User Chat Area -->
        <vue-perfect-scrollbar
          ref="refChatLogPS"
          :settings="perfectScrollbarSettings"
          class="user-chats scroll-area"
        >
          <chat-log
            v-if="activeConversation"
            :active-conversation="activeConversation"
          />
        </vue-perfect-scrollbar>

        <!-- Message Input -->
        <b-form
          class="chat-app-form"
          @submit.prevent="sendMessage"
        >
          <feather-icon
            class="mr-1 send-file-icon"
            icon="PaperclipIcon"
            size="25"
            @click="$refs.uploadMedia.$el.click()"
          />
          <b-form-file
            ref="uploadMedia"
            v-model="mediaFile"
            accept="image/*"
            :hidden="true"
            plain
            @input="previewMedia"
          />
          <b-input-group class="input-group-merge form-send-message mr-1">
            <b-form-input
              v-model="messageText"
              placeholder="Inserisci il tuo messaggio"
            />
          </b-input-group>
          <b-button
            variant="primary"
            type="submit"
          >
            Invia
          </b-button>
        </b-form>
      </div>
    </section>

    <b-modal
      id="preview-modal"
      title="Invia Immagine nella Conversazione"
      footer-class="d-flex justify-content-end"
      body-class="p-0"
      centered
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Invia Immagine nella Conversazione
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <b-img
        v-if="mediaUrl"
        :src="mediaUrl"
        alt="Immagine Sala Riunioni"
        style="width: 100%"
      />
      <template #modal-footer="{ ok, cancel }">
        <b-button
          size="sm"
          variant="danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          size="sm"
          variant="success"
          @click="
            ok()
            sendMedia()
          "
        >
          Invia
        </b-button>
      </template>
    </b-modal>

    <!-- Active Chat Contact Details Sidebar -->
    <!-- <chat-active-chat-content-details-sidedbar
      :shall-show-active-chat-contact-sidebar.sync="shallShowActiveChatContactSidebar"
      :contact="activeConversation || {}"
    /> -->

    <!-- Sidebar -->
    <portal to="content-renderer-sidebar-left">
      <chat-left-sidebar
        :key="sidebarKey"
        :chats-contacts="chatsContacts"
        :contacts="contacts"
        :active-chat-contact-id="activeConversationSid"
        :shall-show-user-profile-sidebar.sync="shallShowUserProfileSidebar"
        :profile-user-data="profileUserData"
        :profile-user-minimal-data="profileUserDataMinimal"
        :mq-shall-show-left-sidebar.sync="mqShallShowLeftSidebar"
        @show-user-profile="showUserProfileSidebar"
        @open-chat="setActiveChat($event)"
      />
    </portal>

    <b-modal
      id="create-modal"
      title="Nuova Conversazione"
      footer-class="d-flex justify-content-end"
      centered
      no-close-on-backdrop
    >
      <template #modal-header="{ close }">
        <h5 class="mb-0">
          Nuova Conversazione
        </h5>
        <feather-icon
          icon="XIcon"
          size="18"
          @click="close()"
        />
      </template>
      <template>
        <b-form>
          <b-form-group
            label="Nome della Conversazione"
            label-for="conversation_name"
          >
            <b-form-input
              id="conversation_name"
              v-model="newConversation.conversation_name"
            />
          </b-form-group>
          <b-row class="align-items-center">
            <b-col cols="12">
              <b-form-group
                label="Aggiungi Partecipante"
                label-for="conversation_participant"
              >
                <v-select
                  id="conversation_participant"
                  v-model="newParticipant"
                  :filterable="false"
                  :options="usersPaginated"
                  @open="onOpen"
                  @close="onClose"
                  @search="(query) => (search = query)"
                  @input="addParticipant"
                >
                  <template #list-footer>
                    <li
                      v-show="hasNextPage"
                      ref="load"
                      class="loader"
                    >
                      Carica più Utenti...
                    </li>
                  </template>
                </v-select>
              </b-form-group>
            </b-col>
            <!-- <b-col cols="3" class="mr-0 ml-0">
              <b-button size="sm" variant="primary" @click="addParticipant()"> Aggiungi </b-button>
            </b-col> -->
            <b-col
              class="text-center"
              cols="12"
            >
              <b-list-group :key="componentKey">
                <b-list-group-item
                  v-for="(participant, key) in newParticipants"
                  :key="participant.value"
                  class="d-flex align-items-center justify-content-between"
                >
                  <h6 class="mb-0">
                    {{ participant.label }}
                  </h6>
                  <b-button
                    variant="danger"
                    size="sm"
                    @click="deleteParticipant(key)"
                  ><feather-icon
                    icon="XIcon"
                  /></b-button>
                </b-list-group-item>
              </b-list-group>
            </b-col>
          </b-row>
        </b-form>
      </template>
      <template #modal-footer="{ ok, cancel }">
        <b-button
          variant="outline-danger"
          @click="cancel()"
        >
          Annulla
        </b-button>
        <b-button
          variant="success"
          @click="
            ok()
            createConversation()
          "
        >
          Inizia
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import store from '@/store'
import { ref, onUnmounted, nextTick } from '@vue/composition-api'
import {
  BAvatar,
  BDropdown,
  BDropdownItem,
  BForm,
  BInputGroup,
  BFormInput,
  BButton,
  BModal,
  BFormGroup,
  BCol,
  BRow,
  BListGroup,
  BListGroupItem,
  BFormFile,
  BImg,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
// import { formatDate } from '@core/utils/filter'
import { $themeBreakpoints } from '@themeConfig'
import { useResponsiveAppLeftSidebarVisibility } from '@core/comp-functions/ui/app'
import { useToast } from 'vue-toastification/composition'
import router from '@/router'
import userStoreModule from '@/views/apps/user/userStoreModule'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { Client as ConversationsClient } from '@twilio/conversations'
import { avatarText } from '@core/utils/filter'
import useChat from './useChat'
import ChatLog from './ChatLog.vue'
import ChatActiveChatContentDetailsSidedbar from './ChatActiveChatContentDetailsSidedbar.vue'
import chatStoreModule from './chatStoreModule'
import ChatLeftSidebar from './ChatLeftSidebar.vue'

export default {
  components: {
    // BSV
    BAvatar,
    BDropdown,
    BDropdownItem,
    BForm,
    BInputGroup,
    BFormInput,
    BButton,
    BModal,
    BFormGroup,
    BCol,
    BRow,
    BListGroup,
    BListGroupItem,
    BFormFile,
    BImg,

    // 3rd Party
    vSelect,
    VuePerfectScrollbar,

    // SFC
    ChatLeftSidebar,
    ChatActiveChatContentDetailsSidedbar,
    ChatLog,
  },
  setup() {
    const toast = useToast()

    const USER_APP_STORE_MODULE_NAME = 'app-user'
    const CHAT_APP_STORE_MODULE_NAME = 'app-chat'

    // Register module
    if (!store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.registerModule(CHAT_APP_STORE_MODULE_NAME, chatStoreModule)
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) {
      store.registerModule(USER_APP_STORE_MODULE_NAME, userStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(CHAT_APP_STORE_MODULE_NAME)) store.unregisterModule(CHAT_APP_STORE_MODULE_NAME)
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const users = ref(null)
    const _users = []
    store
      .dispatch('app-user/fetchUsers', { per_page: 1000 })
      .then(response => {
        response.data[0].forEach((user, i) => {
          const _user = {
            label: `#${user.anagrafico_id} - ${user.display_name}`,
            value: user.id,
          }
          _users.push(_user)
        })
      })
      .catch(error => {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          window.location.reload(true)
        }
      })
    users.value = _users

    const { resolveAvatarBadgeVariant } = useChat()

    // Scroll to Bottom ChatLog
    const refChatLogPS = ref(null)
    const scrollToBottomInChatLog = () => {
      const scrollEl = refChatLogPS.value.$el || refChatLogPS.value
      scrollEl.scrollTop = scrollEl.scrollHeight
    }

    // ------------------------------------------------
    // Chats & Contacts
    // ------------------------------------------------
    const chatsContacts = ref([])
    const contacts = ref([])

    const fetchConversations = () => {
      store
        .dispatch('app-chat/fetchConversations', { sort_by: 'last_activity_at', sort_direction: 'DESC', all: true })
        .then(response => {
          chatsContacts.value = response.data[0]
        })
    }

    fetchConversations()

    // const fetchChatAndContacts = () => {
    //   store.dispatch('app-chat/fetchChatsAndContacts').then((response) => {
    //     chatsContacts.value = response.data.chatsContacts
    //     contacts.value = response.data.contacts
    //     // eslint-disable-next-line no-use-before-define
    //     profileUserDataMinimal.value = response.data.profileUser
    //   })
    // }

    // fetchChatAndContacts()

    // ------------------------------------------------
    // Single Chat
    // ------------------------------------------------
    const activeChat = ref({})
    const chatInputMessage = ref('')
    const openChatOfContact = conversationId => {
      // Reset send message input value
      chatInputMessage.value = ''

      store.dispatch('app-chat/getTwilioConversation', { conversationId }).then(response => {
        activeChat.value = response.data

        // Set unseenMsgs to 0
        const contact = chatsContacts.value.find(c => c.id === userId)
        if (contact) contact.chat.unseenMsgs = 0

        // Scroll to bottom
        nextTick(() => {
          scrollToBottomInChatLog()
        })
      })

      // if SM device =>  Close Chat & Contacts left sidebar
      // eslint-disable-next-line no-use-before-define
      mqShallShowLeftSidebar.value = false
    }

    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    }

    // User Profile Sidebar
    // ? Will contain all details of profile user (e.g. settings, about etc.)
    const profileUserData = ref({})
    // ? Will contain id, name and avatar & status
    const profileUserDataMinimal = ref({})

    const shallShowUserProfileSidebar = ref(false)
    const showUserProfileSidebar = () => {
      store.dispatch('app-chat/getProfileUser').then(response => {
        profileUserData.value = response.data
        shallShowUserProfileSidebar.value = true
      })
    }

    // Active Chat Contact Details
    const shallShowActiveChatContactSidebar = ref(false)

    // UI + SM Devices
    // Left Sidebar Responsiveness
    const { mqShallShowLeftSidebar } = useResponsiveAppLeftSidebarVisibility()
    const startConversation = () => {
      if (store.state.app.windowWidth < $themeBreakpoints.lg) {
        mqShallShowLeftSidebar.value = true
      }
    }

    const mediaEndpoint = process.env.VUE_APP_MEDIA_URL

    const resolveUserRoleVariant = role => {
      if (role === 'user') return 'info'
      if (role === 'hr') return 'warning'
      if (role === 'reception_hub') return 'warning'
      if (role === 'reception_colle') return 'warning'
      if (role === 'accountant') return 'warning'
      if (role === 'admin') return 'danger'
      return 'secondary'
    }

    return {
      // Filters
      // formatDate,

      // useChat
      resolveAvatarBadgeVariant,

      // Chat & Contacts
      chatsContacts,
      contacts,

      // Single Chat
      refChatLogPS,
      activeChat,
      chatInputMessage,
      openChatOfContact,
      scrollToBottomInChatLog,

      // Profile User Minimal Data
      profileUserDataMinimal,

      // User Profile Sidebar
      profileUserData,
      shallShowUserProfileSidebar,
      showUserProfileSidebar,

      // Active Chat Contact Details
      shallShowActiveChatContactSidebar,

      // UI
      perfectScrollbarSettings,

      // UI + SM Devices
      resolveUserRoleVariant,
      avatarText,
      startConversation,
      mqShallShowLeftSidebar,
      newConversation: {
        conversation_name: null,
        exclude_me: true,
        participants: [],
      },
      newParticipant: null,
      newParticipants: [],
      users,
      componentKey: 0,
      sidebarKey: 0,
      statusString: '',
      activeConversation: null,
      activeConversationSid: null,
      activeConversationDetails: null,
      activeParticipant: null,
      isConnected: false,
      observer: null,
      limit: 10,
      search: '',
      messageText: '',
      mediaFile: null,
      mediaUrl: null,
      mediaEndpoint,
    }
  },
  computed: {
    usersFiltered() {
      return this.users.filter(data => JSON.stringify(data).toLowerCase().indexOf(this.search.toLowerCase()) !== -1)
    },
    usersPaginated() {
      return this.usersFiltered.slice(0, this.limit)
    },
    hasNextPage() {
      return this.usersPaginated.length < this.usersFiltered.length
    },
  },
  mounted() {
    this.initConversationsClient()
    this.observer = new IntersectionObserver(this.infiniteScroll)
  },
  methods: {
    setActiveParticipant(participant) {
      this.activeParticipant = participant
    },
    previewMedia() {
      this.mediaUrl = URL.createObjectURL(this.mediaFile)
      this.$bvModal.show('preview-modal')
    },
    sendMedia() {
      const formData = new FormData()
      formData.append('file', this.mediaFile)
      this.activeConversation.sendMessage(formData)
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick()
        this.observer.observe(this.$refs.load)
      }
    },
    onClose() {
      this.observer.disconnect()
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent
        const { scrollTop } = target.offsetParent
        this.limit += 10
        await this.$nextTick()
        ul.scrollTop = scrollTop
      }
    },
    sendMessage() {
      this.activeConversation.sendMessage(this.messageText).then(() => {
        this.messageText = ''
      })
      store
        .dispatch('app-chat/fetchConversations', {
          sort_by: 'last_activity_at',
          sort_direction: 'DESC',
          all: true,
        })
        .then(result => {
          this.chatsContacts = result.data[0]
        })
      this.$nextTick(() => {
        const container = this.$refs.refChatLogPS
        container.scrollTop = container.scrollHeight
      })
    },
    async setActiveChat(sid) {
      this.activeConversation = null
      this.activeConversation = await this.conversationsClient.getConversationBySid(sid)
      this.activeConversationSid = sid
      const details = await store.dispatch('app-chat/getTwilioConversation', { sid })
      store.dispatch('app-chat/getConversation', { id: details.data.id }).then(result => {
        this.activeConversationDetails = result.data
        this.activeParticipant = result.data.participants[0][0]
      })
      this.$nextTick(() => {
        const container = this.$refs.refChatLogPS
        container.scrollTop = container.scrollHeight
      })
    },
    addParticipant() {
      let duplicated = []
      if (this.newParticipants.length > 0) {
        duplicated = this.newParticipants.filter(el => el.value == this.newParticipant.value)
      }
      if (duplicated.length > 0) {
        this.newParticipant = null
        duplicated = []
        return this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Errore Inserimento',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
            text: 'Utente già inserito come Partecipante della Conversazione',
          },
        })
      }
      this.newParticipants.push(this.newParticipant)
      this.newParticipant = null
    },
    deleteParticipant(key) {
      this.$delete(this.newParticipants, key)
      this.componentKey++
    },
    async createConversation() {
      this.newConversation.participants = []
      for (let i = 0; i < this.newParticipants.length; i++) {
        this.newConversation.participants.push(this.newParticipants[i].value)
      }

      try {
        const result = await store.dispatch('app-chat/createConversation', this.newConversation)
        this.setActiveChat(result.data.twilio_conversation_sid)
        this.activeConversationSid = result.data.twilio_conversation_sid
        this.newConversation = {
          conversation_name: null,
          exclude_me: true,
          participants: [],
        }
        try {
          const result = await store.dispatch('app-chat/fetchConversations', {
            sort_by: 'last_activity_at',
            sort_direction: 'DESC',
          })
          this.chatsContacts = result.data[0]
        } catch (error) {
          if (error === 401) {
            localStorage.removeItem('userData')
            store.commit('user/updateUserData', null)
            window.location.reload(true)
          }
        }
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Conversazione creata con successo',
            icon: 'MessageSquareIcon',
            variant: 'success',
          },
        })
      } catch (error) {
        if (error === 401) {
          localStorage.removeItem('userData')
          store.commit('user/updateUserData', null)
          this.$router.replace({ name: 'auth-login' }).then(() => {
            this.$toast({
              component: ToastificationContent,
              position: 'top-right',
              props: {
                title: 'Sessione scaduta',
                icon: 'AlertTriangleIcon',
                text: "Sessione scaduta, effettuare di nuovo l'accesso per utilizzare tutte le funzionalità",
                variant: 'danger',
              },
            })
          })
        }
      }
    },
    async initConversationsClient() {
      const token = await store.dispatch('app-chat/getTwilioToken')
      window.conversationsClient = ConversationsClient
      this.conversationsClient = new ConversationsClient(token.data.token)
      this.statusString = 'Connessione alla Chat...'
      this.conversationsClient.on('connectionStateChanged', state => {
        switch (state) {
          case 'connected':
            this.statusString = 'Sei Connesso.'
            this.isConnected = true
            break
          case 'disconnecting':
            this.statusString = 'Disconnessione dalla Chat...'
            this.isConnected = false
            break
          case 'disconnected':
            this.statusString = 'Disconnesso.'
            this.isConnected = false
            break
          case 'denied':
            this.statusString = 'Connessione Fallita.'
            this.isConnected = false
            break
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.loader {
  text-align: center;
  color: #bbbbbb;
}
.send-file-icon:hover {
  cursor: pointer;
}
::v-deep .dropdown-menu {
  max-height: 355px;
  overflow-y: auto;
}
</style>

<style lang="scss">
@import '~@core/scss/base/pages/app-chat.scss';
@import '~@core/scss/base/pages/app-chat-list.scss';
</style>
