import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    createConversation(ctx, conversationData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/conversations', conversationData)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/users')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchConversations(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/conversations/list', { params: queryParams })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    getTwilioToken() {
      return new Promise((resolve, reject) => {
        axios
          .get('/conversations/twilio/token')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    getTwilioConversation(ctx, { sid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/conversations/twilio/detail/${sid}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    getConversation(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/conversations/detail/${id}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    fetchChatsAndContacts() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/chats-and-contacts')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    getProfileUser() {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/chat/users/profile-user')
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    getChat(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/apps/chat/chats/${userId}`)
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
    sendMessage(ctx, { contactId, message, senderId }) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/apps/chat/chats/${contactId}`, { message, senderId })
          .then((response) => resolve(response))
          .catch((error) => reject(error))
      })
    },
  },
}
